import React from 'react'
import {Link} from 'react-router-dom'
import '../../css/Homebanner.css';
import banner1 from '../../assets/images/homebanner.png'
import bannerhome from '../../assets/images/banner-home.png'
import banner from '../../assets/images/Banner.png';
import CheckIcon from '@mui/icons-material/Check';
import story from '../../assets/images/r3-img-1.webp';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';


function Homebanner() {
  return (
    <div>
      <div className="banner">
    <div className='container'>
    <div className='row'>
        <div className="col-lg-6">
                <h1 className='homebanner-title'> End-to-End Business Solutions
   Provider</h1>
                <p className='homebanner-des'>Let us help you succeed with tailored solutions, every step of the way. 
                Together, we turn your business goals into reality.</p>
                <Link to="/contact" style={{textDecoration:'none', color:'White'}}> <button className='banner-button'>Let's Connect
   <span className='button-icon'><ArrowCircleRightIcon/></span>  
 </button></Link>
          </div>
            <div className="col-lg-6">
              <img src={bannerhome} alt="" className='homebanner-img'/> 
            </div>
           
      
      </div>
    {/* <div className="homebanner-container">
      <div className='text-column'>
      <h1 className='heading-homebanner'>
        End-to-End<br/>
                 <span>Business Solutions</span> <br/>
                 <span>Provider</span>
         </h1>         
         <p className='description'>Let us help you succeed with tailored solutions, every step of the way. 
        Together, we turn your business goals into reality.
        
         </p>
        <Link to="/contact" style={{textDecoration:'none', color:'White'}}> <button className='banner-button'>Let's Connect
   <span className='button-icon'><ArrowCircleRightIcon/></span>  
 </button></Link>
      </div>
      <img src={bannerhome} alt="" />
    </div> */}
    </div>
    {/* <div className='banner-mob'>
      
      
      <img src={bannerhome} alt=""  className='bannerimage-mob'/>
       

    
     
     <h1 className='heading-mob'>
        End-to-End      Business Solutions <br/>
        <span>Provider</span>
        </h1>
        <p className='description-mob'>Your Business, Your Queries, Our Expertise, Our 
        Innovative Solutions: Let Us Help You Succeed!
        
        </p>        <Link to='/contact'  style={{textDecoration:'none', color:'White'}}><button className='banner-button'>Let's Connect
  <span className='button-icon'><ArrowCircleRightIcon/></span>  
 </button></Link> 
      
      
    </div>  */}

   </div>

   <div className="banner-mob">
    <div className='container'>
    <div className='row'>
        <div className="col-lg-6">
        <img src={bannerhome} alt="" className='homebanner-img'/> 
               
          </div>
            <div className="col-lg-6">
            <h1 className='homebanner-title'> End-to-End Business Solutions
   Provider</h1>
                <p className='homebanner-des'>Let us help you succeed with tailored solutions, every step of the way. 
                Together, we turn your business goals into reality.</p>
                <Link to="/contact" style={{textDecoration:'none', color:'White'}}> <button className='banner-button'>Let's Connect
   <span className='button-icon'><ArrowCircleRightIcon/></span>  
 </button></Link>
            </div>
           
      
      </div>
    {/* <div className="homebanner-container">
      <div className='text-column'>
      <h1 className='heading-homebanner'>
        End-to-End<br/>
                 <span>Business Solutions</span> <br/>
                 <span>Provider</span>
         </h1>         
         <p className='description'>Let us help you succeed with tailored solutions, every step of the way. 
        Together, we turn your business goals into reality.
        
         </p>
        <Link to="/contact" style={{textDecoration:'none', color:'White'}}> <button className='banner-button'>Let's Connect
   <span className='button-icon'><ArrowCircleRightIcon/></span>  
 </button></Link>
      </div>
      <img src={bannerhome} alt="" />
    </div> */}
    </div>
    {/* <div className='banner-mob'>
      
      
      <img src={bannerhome} alt=""  className='bannerimage-mob'/>
       

    
     
     <h1 className='heading-mob'>
        End-to-End      Business Solutions <br/>
        <span>Provider</span>
        </h1>
        <p className='description-mob'>Your Business, Your Queries, Our Expertise, Our 
        Innovative Solutions: Let Us Help You Succeed!
        
        </p>        <Link to='/contact'  style={{textDecoration:'none', color:'White'}}><button className='banner-button'>Let's Connect
  <span className='button-icon'><ArrowCircleRightIcon/></span>  
 </button></Link> 
      
      
    </div>  */}

   </div>

   </div>
   
   
 
    
  )
}

export default Homebanner
// import { useState, useEffect } from "react";
// import "../../css/Accordion.css";
// import "../Accordion/AccordionData"

// const Accordion = ({ items, keepOtherOpen}) => {

//     const [accordionItems, setAccordionItems] = useState(null)

//     useEffect(() =>{

//         if(items){
//             setAccordionItems([
//                 ...items.map(item => ({
//                     ...item, 
//                     toggled: false
//                 }))

//             ])
//         }
//     }, [items])

//     function handleAccordionToggle(clickedItem){
//         setAccordionItems([
//             ...accordionItems.map((item) => {
//                 let toggled = item.toggled

//                 if (clickedItem.id === item.id){
//                     toggled = !item.toggled
//                 } else if (!keepOtherOpen){
//                     toggled = false
//                 }

//                 return {
//                     ...item,
//                     toggled
//                 }

//             })
//         ])
//     }


//     return(
//         <div className="accordion-parent">
//             {accordionItems?.map((listItem, key) =>{
//                 return (
//                     <div className={`accordion ${listItem.toggled ? 'toggled' : ''}`} key={key}>
//                         <button className="toggle" onClick={()=> handleAccordionToggle(listItem)}>

//                             <p>{listItem.label}</p>
//                             <div className="direction-indicator">
//                                 {listItem.toggled ? '-':'+'}
//                             </div>
//                         </button>
//                         <div className="content-parent">
//                                 <div className="content">{listItem.renderContent()}</div>
//                         </div>

//                     </div>
//                 )
//             })

//             }

//         </div>
//     )

        
    
// }

// export default Accordion


import React, { useState } from "react";
import '../../css/Accordion.css'
import { FaArrowCircleRight } from "react-icons/fa";
import { FaArrowCircleDown } from "react-icons/fa";


const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className="accordion-header"
            onClick={() => handleAccordionClick(index)}
          >
            <h3>{item.label}</h3>
            <span>{activeIndex === index ?  <FaArrowCircleDown style={{fontSize:28, color:'black'}}/>: <FaArrowCircleRight style={{fontSize:28}}/>}</span>
          </div>
          <div
            className={`accordion-content ${
              activeIndex === index ? "active" : ""
            }`}
          >
            {activeIndex === index && <div>{item.content}</div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;


import React from 'react'
import '../../../css/iosApp.css'
import ios from '../../../assets/images/App.png'
import ClientLogohead from '../../Home/ClientLogohead'
import { BiCheck } from "react-icons/bi";
import { FaAnglesRight } from "react-icons/fa6";
import iosapp from '../../../assets/images/ios-1-1.jpg';
import android from '../../../assets/images/Android.jpg';
import uiux from '../../../assets/images/UI-UX.jpg';
import apptest from '../../../assets/images/App-Testing.jpg';
import appmaintenance from '../../../assets/images/App-Maintenance-Support.jpg'
import slide_image_1 from '../../../assets/images/End-to-End-Service.jpg'
import slide_image_2 from '../../../assets/images/Cutting-Edge-Technology.jpg'
import slide_image_3 from '../../../assets/images/User-Centric-Design.jpg'
import slide_image_4 from '../../../assets/images/Expert-Developers.jpg'
import slide_image_5 from '../../../assets/images/Tailored-Solutions.jpg'
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {EffectCoverflow, Pagination, Navigation} from 'swiper/modules';
import ContactFrom from '../../Services/ContactFrom';

const Iosapp = () => {
  return (
    <div>
        <div className='sale_container_bg'>
            <div className="sale_banner">
            <img src={ios} alt="" className='sale_banner_img'/>

            <div className="sale_text_col">
            <h1 className='sale_banner_title'>Transform Ideas into
            Engaging Apps</h1>
            
            <p>Create The Next Big Thing In Mobile with Rio - Your Partner in Custom iOS & Android App Development! We specialize in crafting bespoke iOS and Android applications that meet your unique requirements and provide an exceptional user experience. Our mission is to turn your innovative ideas into powerful, functional, and aesthetically pleasing mobile applications that stand out in the competitive digital landscape. </p> 
            <button className='brand_button'>Contact us<BiCheck className='ipr_icon1'/>

</button>
            </div>
            </div>
        </div>
        <ClientLogohead/>
        <hr className='b-line' />
        <div className="mid-content">
          <div className="app-head-line">
          Why invest in Custom iOS & Android App
          Development?
          </div>
          <p className="head-con">
          Opting for custom iOS and Android app development provides numerous benefits that can elevate your business. Here’s why:
          </p>
          </div>
          <div className="stand-grid">
      <div className="expertise-ios">
        <div className="expertise-h-ios">
        <div className="ios-icon"><FaAnglesRight /></div>
        Tailored Solutions
      </div>
      <div className="expertise-con">
      Custom apps are designed specifically for your business needs, ensuring enhanced functionality and user experience, leading to higher engagement and satisfaction.
      </div>
      </div>
      <div className="expertise">
        <div className="expertise-h-ios">
        <div className="ios-icon"><FaAnglesRight /></div>
        Competitive Edge
      </div>
      <div className="expertise-con">
      Stand out in the market with unique features and functionalities that differentiate your business from competitors, offering a distinct value proposition. </div>
      </div>
      <div className="expertise">
        <div className="expertise-h-ios">
        <div className="ios-icon"><FaAnglesRight /></div>
        Scalability and Flexibility
      </div>
      <div className="expertise-con">
      Custom apps grow with your business. They are built to easily scale and adapt to your evolving needs, accommodating new features and a larger user base.</div>
      </div>
      <div className="expertise">
        <div className="expertise-h-ios">
        <div className="ios-icon"><FaAnglesRight /></div>
        Enhanced Security
      </div>
      <div className="expertise-con">
      Benefit from robust, tailored security features that protect your sensitive data against breaches and cyber threats.
      </div>
      </div>
      <div className="expertise">
        <div className="expertise-h-ios">
        <div className="ios-icon"><FaAnglesRight /></div>
        Seamless Integration
      </div>
      <div className="expertise-con">
      Custom apps integrate smoothly with your existing systems and workflows, enhancing efficiency and reducing errors.
      </div>
      </div>
      <div className="expertise">
        <div className="expertise-h-ios">
        <div className="ios-icon"><FaAnglesRight /></div>
        Superior Performance
      </div>
      <div className="expertise-con">
      Optimized for performance, custom apps ensure fast load times, intuitive navigation, and reliable functionality, providing a better user experience.
      </div>
      </div>
      <div className="expertise">
        <div className="expertise-h-ios">
        <div className="ios-icon"><FaAnglesRight /></div>
        Direct Customer Engagement
      </div>
      <div className="expertise-con">
      Engage directly with your customers through features like push notifications, in-app messaging, and personalized content, fostering better communication and feedback.x
      </div>
      </div>
      <div className="expertise">
        <div className="expertise-h-ios">
        <div className="ios-icon"><FaAnglesRight /></div>
        Cost-Effective
      </div>
      <div className="expertise-con">
      While initially more expensive, custom apps are cost-effective in the long run with lower maintenance costs and better ROI by precisely meeting your needs.
      </div>
      </div>
      <div className="expertise">
        <div className="expertise-h-ios">
        <div className="ios-icon"><FaAnglesRight /></div>
        Ongoing Support
      </div>
      <div className="expertise-con">
      Receive dedicated support and maintenance to keep your app updated with the latest technology trends and user requirements.
      </div>
      </div>
      </div>
      <div className='container'>
        <hr/>
      </div>
      
      <div className="ios-head-line">
      Services We Offer
          </div>
         
      <div className="ios-service">
        <div className="ios-service-container">
          <img src={iosapp} alt="" className='ios-service-img' />
          <div className='text-col'>
          <h2 className='ios-service-title'>iOS App<br/>
         Development</h2>
         <p className='ios-service-para'>Leveraging Swift and Objective-C, we create high-performance iOS apps that provide a seamless experience on all Apple devices.</p>
       
          </div>
          </div>
          <div className="ios-service-container">
          <img src={android} alt="" className='ios-service-img'/>
          <div className='text-col'>
          <h2 className='ios-service-title'>Android App
          <br/>
          Development
          </h2>
         <p className='ios-service-para'>
         Utilizing Kotlin and Java, we build robust Android apps compatible with the latest devices and operating systems.</p>
       
          </div>
          </div>
          <div className="ios-service-container">
          <img src={uiux} alt="" className='ios-service-img'/>
          <div className='text-col'>
          <h2 className='ios-service-title'>UI/UX Design
          </h2>
         <p className='ios-service-para'>
         Our design team crafts beautiful, user-friendly interfaces that keep your users engaged.</p>
       
          </div>
          </div>
          <div className="ios-service-container">
          <img src={apptest} alt="" className='ios-service-img'/>
          <div className='text-col'>
          <h2 className='ios-service-title'>App Testing Q&A
          </h2>
         <p className='ios-service-para'>Rigorous testing ensures your app is reliable, bug-free, and ready for launch.</p>
       
          </div>
          </div>
          <div className="ios-service-container">
          <img src={appmaintenance} alt="" className='ios-service-img'/>
          <div className='text-col'>
          <h2 className='ios-service-title'>App Maintenance &
          Support
          </h2>
         <p className='ios-service-para'>
         Post-launch, we offer ongoing support and maintenance to keep your app running smoothly and efficiently.</p>
          </div>
          </div>
      </div>
      <div className='container'>
        <hr/>
      </div>
      <div className="ios-head-line">
      Why Choose Us?
          </div>
          <p className='ios-choose-para'>By choosing RIO, you're not just getting an app; you're gaining a dedicated partner
          committed to your success.</p>
            {/* <Swiper 
            effect={ 'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={'auto'}
            coverflowEffect={
              {
                rotate:0,
                stretch:0,
                depth:100.0,
                modifier:2.5
              }
            }
            pagination={{el:'.swiper-pagination',clickable:true}}
            navigation={{
              nextEl:'.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className='swiper_container'
            >
              <SwiperSlide>
                <img src={slide_image_1} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slide_image_2} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slide_image_3} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slide_image_4} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slide_image_5} alt="" />
              </SwiperSlide>
              
              <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </div>
              <div className="swiper-button-next slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
             </div>
              <div className="swiper-pagination"></div>
        </div>
            </Swiper> */}
            <h3 className='web_contact_title'>Ready to transform your business with RIO's
            Odoo development services?</h3>
            <p className='web_contact_para'>Contact us today for a free consultation and discover how we can help you achieve your business goals.

</p>
            <ContactFrom/>
    </div>
  )
}

export default Iosapp
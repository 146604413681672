import React from 'react'
import '../css/Footer.css';
import {Link} from 'react-router-dom'
import FooterLogo from '../assets/images/RIO-lOGO-1.png';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import US from '../assets/images/US.jpg';
import India from '../assets/images/India.jpg';
import { FaQuora } from "react-icons/fa6";

 function Footerbottom() {
  return (
    <div>
    <div>
        <div className='footer'>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                <img src={FooterLogo} alt='' className='footer_title'/>
            <div className='social_media'>
            
          <FacebookRoundedIcon color='#fff' className='social_icon' sx={{ fontSize: 30 }}/>
            <LinkedInIcon color='#fff' sx={{ fontSize: 30 }}  className='social_icon'/>
            <YouTubeIcon sx={{ fontSize: 30 }} className='social_icon'/>
            <InstagramIcon sx={{ fontSize: 30 }} className='social_icon'/>
            
           
           
         </div>
                </div>
                <div className="col-lg-4 col-md-6">
                <h5 className='footer_title1'>Services</h5>
             <ul className='footer_menu'>
               <li>Digital Marketing</li>
               <li>Web Development</li>
                <li>App Development</li>
               <li>Staffing</li>
                <li>Odoo ERP</li>
             <li>IPR Services</li>
             </ul>
                </div>
                <div className="col-lg-4 col-md-6">
                <h5 className='footer_title2'>Let's Connect</h5>
                <ul >
            
                 <li className='footer_menu2'>
                     <WifiCalling3Icon className='footer_icon'/>
                     
        +91 88849 10777
      </li>
                    <li className='footer_menu2'>
                     <span><WifiCalling3Icon className='footer_icon'/>
                     
        +1 760 284 6711
      </span></li>
                        <li className='footer_menu2'>
                         <AttachEmailIcon className='footer_icon'/>
                         info@riobizsols.com</li>

                         </ul>

                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-custom col-12 col-md-6">

                </div>
                <div className="col-custom col-12 col-md-6">
                    <h1 className='foot-loc'>Our Location</h1>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-4">
                <p className='copyright'>© Copyright 2024 By <b>RIO BizSols PVT LTD</b></p>

                </div>
                
                <div className="col-12 col-md-4">
                <img src={US} alt="US" className='footer_location_img1'/>
                <h5 className='location_title'>USA Office</h5>
                  <p className='location_title'>180 Steuart St, #192750 <br/>SanFrancisco, CA 94119, USA</p>
                </div>
                <div className="col-12 col-md-4">
                <img src={India} alt="US" className='footer_location_img2'/>
                <h5 className='location_title1'>INDIA Office</h5>
           <p className='location_title1'>No.174/96A, Barathi Colony Main Road,<br/> Peelamedu, Coimbatore – <br/>641004, India</p>
       
                </div>
                
                
            </div>
        </div>
    </div>

   
    </div>
   
  </div>  
    
    
   
  )
}

export default Footerbottom

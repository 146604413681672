import React from 'react'
import Homebanner from '../Home/Homebanner'
import Clientlogo from '../Home/Clientlogo'
import Services from '../Home/HomeServices'
import Story from '../Home/Story'
import Team from '../Home/Team'
import Count from '../Home/Count'
import ClientLogohead from '../Home/ClientLogohead'
import HomeContact from '../Home/HomeContact'


function Home() {
  return (
    <div>
        <Homebanner/>
        <ClientLogohead/>
        
        <Services/>
         <Story/> 
          <Count/>    
         <Team/>    
        <HomeContact/> 
        
    </div>
  )
}

export default Home
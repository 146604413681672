import React from 'react'
import '../../../css/seo.css'
import seobanner from '../../../assets/images/seobanner.png'
import { BiCheck } from "react-icons/bi";
import ClientLogohead from '../../Home/ClientLogohead';
import logo from '../../../assets/images/riologo.png'
import high from '../../../assets/images/high-visibility.png'
import quality from '../../../assets/images/high-quality.png'
import user from '../../../assets/images/user.png'
import trust from '../../../assets/images/Credibility_Trust.png'
import stay from '../../../assets/images/Stay.png'
import cost from '../../../assets/images/Cost-Effective-Marketing.png'
import long from '../../../assets/images/Long-Term-Results.png'
import seo from '../../../assets/images/SEO-is-highly-adaptable.png'
import keyword from '../../../assets/images/keywordresearch.png'
import seoon from '../../../assets/images/seo-on.png'
import techseo from '../../../assets/images/SEO.jpg'
import content from '../../../assets/images/content.png'
import linkbulid from '../../../assets/images/linkbuliding.png'
import localseo from '../../../assets/images/localseo.png'
import seochoose from '../../../assets/images/seochoose.png'
import Payperaccordion from '../../Accordion/Payperaccordion';
import ContactFrom from '../../Services/ContactFrom';
import ST from '../../../assets/images/Short-Term-2.jpg'
import LT from '../../../assets/images/temp-hire-1.png'
import TH from '../../../assets/images/Tem-hire.jpg'
import DH from '../../../assets/images/Direct-hire.jpg'
import FH from '../../../assets/images/Fresher-Hiring.jpg'
import LH from '../../../assets/images/Lateral-Hiring.jpg'
import EH from '../../../assets/images/Executive-Hiring.jpg'
import CS from '../../../assets/images/Contractual Staffing.jpg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';


function Seo() {
  const accordionItems = [
    { label: "Expertise and Experience", content: "With years of experience in the SEO industry, our team of experts stays ahead of the curve, continuously updating our techniques to align with the latest search engine algorithms and industry best practices. We pride ourselves on delivering results that matter." },
    { label: "Tailored Strategies", content: "We understand that every business is unique. Our SEO strategies are customized to meet your specific needs and goals. Whether you’re looking to increase local visibility, boost e-commerce sales, or improve overall search rankings, we have the expertise to craft a solution that works for you." },
    { label: "Proven Results", content: "Our track record speaks for itself. We have helped numerous clients achieve top rankings on search engines, resulting in increased traffic, higher conversion rates, and significant revenue growth." },
    { label: "Transparency and Communication", content: "We believe in maintaining open and honest communication with our clients. You’ll receive regular updates and detailed reports to keep you informed of our progress and the results of our efforts." },
    { label: "Holistic Approach", content: "Our holistic approach to SEO ensures that every aspect of your online presence is optimized for success. From technical audits to content strategy, we leave no stone unturned in our quest to boost your search engine performance." },
    
  ];
  return (
    <div>
        <div className="seo_banner_bg">
            <div className="seo_container">
                <div className="seo_text_column">
                <img src={seobanner} alt="" className='sm_banner_img' />
                </div>
                <div className="seo_text_column">
                    <h1 className='seo_banner_title'>
                    With Premier SEO
                    Services
                    </h1>
                    <p className='seo_banner_descr'>
                    Unlock the power of SEO and transform your business’s digital presence with RIO’s SEO services. We specialize in delivering top-tier SEO services designed to elevate your brand, drive organic traffic, and maximize your ROI. Our comprehensive SEO strategies ensure your business stands out from the competition and reaches its full potential online.
                    </p>
                    <button className='content_button'>Let's connect <BiCheck className='content_icon1'/></button>
                </div>
            </div>
        </div>
        <ClientLogohead/>
        <div className="container">
        <hr className='b-line' />
        </div>
        <h2 className='seo_title'>
        Why invest in Search Engine Optimization?
        </h2>
        <p className='seo_txt_description'>
        By investing in SEO, you are investing in the long-term success and growth of your business. Here’s why SEO (Search Engine Optimization) is indispensable for your digital presence -
        </p>
        <section className='seo_wapp'>
            <div className="black seo-grid">
           <img src={high} alt="" /> 
          <h4 className='seo-grid-title'>Boost Your Online Visibility</h4>
          <p className='seo-grid-description'>In a digital-first world, the majority of consumers turn to search engines to find products and services. SEO ensures your business appears prominently in these search results, increasing the likelihood that potential customers will find and visit your site.</p>
          </div>
          <div className="black seo-grid">
          <img src={quality} alt="" /> 
       
          <h4 className='seo-grid-title'>Drive High-Quality Traffic</h4>
          <p  className='seo-grid-description'>SEO targets users who are actively searching for keywords related to your business. This means the traffic driven to your site is more likely to convert, as these visitors are already interested in what you have to offer.</p>
          </div>

            </section>
            <img src={logo} alt='' className='seo_center_logo'/>
            <section className='seo_wapp'>
            <div className="black seo-grid">
           <img src={user} alt="" /> 
          <h4 className='seo-grid-title'>Enhance User Experience</h4>
          <p className='seo-grid-description'>Good SEO practices improve the overall user experience on your site. From faster loading times to mobile-friendly design, SEO ensures that visitors have a positive experience, which can lead to higher engagement and better conversion rates.</p>
          </div>
          <div className="black seo-grid">
           <img src={trust} alt="" />  
          
          
          <h4 className='seo-grid-title'>Build Credibility and Trust</h4>
          <p  className='seo-grid-description'>Websites that rank high on search engine results pages (SERPs) are often perceived as more credible and trustworthy. Effective SEO helps establish your brand’s authority, encouraging users to trust your business over competitors.</p>
          </div> 

            </section>
            <section className='seo_wapp'>
            <div className="black seo-grid">
            <img src={stay} alt="" />  
          <h4 className='seo-grid-title'>Stay Ahead of the Competition</h4>
          <p className='seo-grid-description'>In competitive markets, businesses with strong SEO strategies gain a significant advantage. By optimizing your site for search engines, you can stay ahead of competitors who are not leveraging SEO, capturing a larger share of your target market.</p>
          </div>
          <div className="black seo-grid">
          <img src={cost} alt="" />   
          <h4 className='seo-grid-title'>Cost-Effective Marketing</h4>
          <p  className='seo-grid-description'>Compared to traditional advertising methods, SEO is highly cost-effective. It targets users who are actively searching for your products or services, making it a smart investment that can yield high returns over time.</p>
          </div>

            </section>
            <img src={logo} alt='' className='seo_center_logo'/>
            <section className='seo_wapp'>
            <div className="black seo-grid">
            <img src={long} alt="" />  
          <h4 className='seo-grid-title'>Long-Term Results</h4>
          <p className='seo-grid-description'>Unlike paid advertising, which stops delivering results once the budget runs out, SEO provides long-lasting benefits. Once your site achieves high rankings, it can maintain its position with ongoing optimization, continually driving traffic and leads.</p>
          </div>
          <div className="black seo-grid">
          <img src={seo} alt="" />   
          <h4 className='seo-grid-title'>Adaptability and Measurability</h4>
          <p  className='seo-grid-description'>SEO is highly adaptable and measurable. With analytics tools, you can track your progress, see what’s working, and make data-driven decisions to refine your strategy continually. This adaptability ensures that your SEO efforts remain effective and aligned with your business goals.</p>
          </div>

            </section>
             <h2 className='seo_service_title'>Services We Offer</h2>
            <div className="seo_ser_container">
            <div className="seo_ser">
              <img src={keyword} alt="" className='seo_ser_img'/>
              <div className="seo_ser_bg">
                <h3 className='seo_ser_title'>
                Keyword Research and Analysis
                </h3>
                <p className='seo_ser_para'>We conduct thorough market research to identify the most effective keywords to drive targeted traffic to your site, ensuring your content aligns with your potential customers' searches.</p>
              </div>
            </div>

            <div className='seo_ser1'>
                 <div className="seo_ser_bg1">
                  <h3 className='seo_ser_title1'>On-Page Optimization</h3>
                  <p  className='seo_ser_para1'>Our team enhances your website’s structure, content, and meta tags to improve search engine rankings and user experience, making sure every page is fully optimized for maximum visibility.</p>
                </div> 
              
              <img src={seoon} alt="" className='seo_ser_img1'/>
            </div>
            <div className="seo_ser">
              <img src={techseo} alt="" className='seo_ser_img'/>
              <div className="seo_ser_bg">
                <h3 className='seo_ser_title'>
                Technical SEO
                </h3>
                <p className='seo_ser_para'>We ensure your website is technically sound with optimal loading speeds, mobile responsiveness, and secure HTTPS connections, addressing any technical issues that could hinder your site's performance.</p>
              </div>
            </div>

            <div className='seo_ser1'>
                 <div className="seo_ser_bg1">
                  <h3 className='seo_ser_title1'>Content Creation and <br/> Marketing</h3>
                  <p  className='seo_ser_para1'>We develop high-quality, engaging content that attracts and retains visitors, boosting your site's relevance and authority in search engines through strategic content marketing.</p>
                </div> 
              
              <img src={content} alt="" className='seo_ser_img2'/>
            </div>
            <div className="seo_ser">
              <img src={linkbulid} alt="" className='seo_ser_img'/>
              <div className="seo_ser_bg">
                <h3 className='seo_ser_title'>
                Link Building
                </h3>
                <p className='seo_ser_para'>Our link-building strategies focus on building a robust backlink profile to enhance your site’s authority and credibility through strategic outreach and partnerships with reputable websites.</p>
              </div>
            </div>

            <div className='seo_ser1'>
                 <div className="seo_ser_bg1">
                  <h3 className='seo_ser_title1'>Local SEO</h3>
                  <p  className='seo_ser_para1'>We boost your visibility in local search results to attract more customers in your area by optimizing for local search terms, managing local listings, and encouraging positive reviews.</p>
                </div> 
              
              <img src={localseo} alt="" className='seo_ser_img1'/>
            </div>
            </div> 
            {/* <div className='staff_bg'>
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={keyword} alt=""  className='staff_img_top' />
            <div className='staff_card'>
            <h1>Keyword Research and Analysis</h1>
            <p>We conduct thorough market research to identify the most effective keywords to drive targeted traffic to your site, ensuring your content aligns with your potential customers' searches.</p>
            
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={seoon} alt=""  className='staff_img1' />
            
            <div className="staff_card_right">
              <h1>On-Page Optimization</h1>
              <p>Our team enhances your website’s structure, content, and meta tags to improve search engine rankings and user experience, making sure every page is fully optimized for maximum visibility.</p>
              
            </div>
           
            </Col>
          </Row>
        </div> 
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={TH} alt=""  className='staff_img' />
            <div className='staff_card'>
            <h1>Temp-to-Hire</h1>
            <p>Offering a trial period where clients can assess candidates in real-world work environments before making a permanent hiring decision. Minimize risks and ensure compatibility.</p>
            <button className='staff_button'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={DH} alt=""  className='staff_img1' />
            
            <div className="staff_card_right">
              <h1>Direct Hire</h1>
              <p>Identifying and recruiting top talent for direct placement within client companies. Streamlining the hiring process for permanent positions without the interim period of temporary employment.</p>
              <button className='staff_button_right'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
           
            </Col>
          </Row>
        </div> 
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={FH} alt=""  className='staff_img' />
            <div className='staff_card'>
            <h1>Fresher's Hiring</h1>
            <p>Tap into a pool of enthusiastic fresh graduates ready to bring new perspectives and energy to your organization. We identify and place promising newcomers to help you build a strong, dynamic team.</p>
            <button className='staff_button'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={LH} alt=""  className='staff_img1' />
            
            <div className="staff_card_right">
              <h1>Lateral Hiring</h1>
              <p>Expertly matching candidates with permanent positions within client organizations. Streamlining the hiring process to find the ideal fit for long-term success.</p>
              <button className='staff_button_right'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
           
            </Col>
          </Row>
        </div> 
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={EH} alt=""  className='staff_img' />
            <div className='staff_card'>
            <h1>Executive Hiring</h1>
            <p>Providing flexible staffing solutions to meet short-term needs, cover seasonal fluctuations, or address temporary project demands. Access skilled professionals without long-term commitment.</p>
            <button className='staff_button'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={CS} alt=""  className='staff_img_bottom' />
            
            <div className="staff_card_right">
              <h1>Contractual Staffing</h1>
              <p>Contractual staffing solutions are designed to offer you the flexibility to scale your workforce based on the demands of your business. Whether you need expertise for a short-term project, a long-term assignment, or anything in between, we have the right talent to match your requirements.</p>
              <button className='staff_button_right'>For More Info
            <span className='button-icon'><ArrowCircleRightIcon/></span>
            </button>
            </div>
           
            </Col>
          </Row>
        </div>     
        </div> */}
            <h2 className='seo_service_title'>Why Choose Us?</h2>
            <div className="seo_container">
              <img src={seochoose} alt="" className='seo_choose_img'/>
              <div className='seo_text_column'>
             <div className='seo_accordion'>
             
             <Payperaccordion items={accordionItems} />
             </div>
              

              </div>
              </div>
              <h2 className='pay_title2'>Let’s Discuss Your Project</h2>
        <p className='pay_txt_description'>Ready to take your online presence to the next level? Partner with RIO to experience the transformative power of expert SEO. Let’s start building a strategy that will drive your business forward.</p>
      
       <ContactFrom/>
    </div>
  )
}

export default Seo
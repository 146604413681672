import React from 'react'
import DM from '../../assets/images/Digital-Marketing-1.jpg'
import WD from '../../assets/images/Web-development.jpg'
import App from '../../assets/images/App-development.png'
import staff from '../../assets/images/Staffing.jpg'
import ERP from '../../assets/images/ERP.jpg'
import IP from '../../assets/images/IPR.jpg'
import '../../css/Ser.css'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Divider from '@mui/material/Divider';
import Clientlogo from '../Home/Clientlogo'
import {Link} from 'react-router-dom'

export const MainServices = () => {
  return (
    <div>
        <h1 className='ser_title'>
            We Provide
        </h1>
        <div className="container">
            <div className="row">
                <div className="col-md-6 ">
                    <div className='ser'> <img src={DM} alt=""  className='ser_img'/>
                    <h2>01</h2>
                   <p> <Link to='/our-service/digital-marketing/'><ArrowOutwardIcon/></Link></p>
                    <h1>Digital Marketing</h1>
                    </div>
                
                </div>
                <div className="col-md-6">
                <div className='ser'>  
                <h2>02</h2>
                <p> <ArrowOutwardIcon/></p>
                <h1>Web Development</h1>
                <img src={WD} alt=""  className='ser_img'/>
                </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                <div className='ser'>
                <h2>03</h2>
                <p> <Link to='/our-service/app-development'><ArrowOutwardIcon/></Link></p>
                <h1>App Develpment</h1>
                    <img src={App} alt=""  className='ser_img'/>
                    </div>
                </div>
                <div className="col-md-6">
                <div className='ser'>
                <h2>04</h2>
                <p> <Link to='/our-service/staffing'><ArrowOutwardIcon/></Link></p>
                <h1>Staffing</h1>
                <img src={staff} alt=""  className='ser_img'/>
                </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className='ser'>
                    <h2>05</h2>
                    <p> <Link to='/our-service/odoo/'><ArrowOutwardIcon/></Link></p>
                    <h1>ERP Software</h1>
                    <img src={ERP} alt=""  className='ser_img'/>
                    </div>
                
                </div>
                <div className="col-md-6">
                <div className='ser'>
                <h2>06</h2>
                <p> <Link to='/our-service/ipr/'><ArrowOutwardIcon/></Link></p>
                <h1>Intellectual Property Rights</h1>
                <img src={IP} alt=""  className='ser_img'/>
                </div>
                </div>
            </div>
        </div>
        <div className="container">
        <Divider className="col-12 col-md-6 divider-ser"/>
        <div className="client">
            <p className='client_subhead'>OUR CLIENT</p>
            <h1>
            Trusted By 1,000+ Companies
            </h1>
            <Clientlogo/>
        </div>
        </div>
       
    </div>
  )
}

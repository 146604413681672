import React from 'react'
import '../../../css/salemanagement.css'
import fin from '../../../assets/images/odoo_fin.png'
import ClientLogohead from '../../Home/ClientLogohead'
import account from '../../../assets/images/accounting.jpg'
import invoice from '../../../assets/images/invoicing.jpg'
import expense from '../../../assets/images/expense.jpg'
import ContactFrom from '../../Services/ContactFrom'

function FinanceManagement() {
  return (
    <div>
       <div className="sale_container_bg">
      <div className="sale_banner">
      <img src={fin} alt="" className='sale_banner_img'/>
      <div className="sale_text_col">
      <h1 className='sale_banner_title'>Experience the Future of
      Business with RIO</h1>
      <h3 className='sale_banner_subtitle'>Your Partner in Cutting-Edge Odoo ERP Solutions.</h3>
                   <p>As a premier Odoo ERP development company, RIO specializes in crafting robust and bespoke business solutions tailored to our client's unique needs. Trust us to optimize your business processes, enhance productivity, and propel your organization to new heights with our state-of-the-art Odoo ERP implementation.</p>     
        </div>
        </div>

      </div>
      <ClientLogohead/>
      <div className='container'>
        <hr/>
      </div>
      <div className="container">
        <div className="sale_banner1">
        <img src={account} alt=""  className='sale_image' />
        <div className="sale_text_col1">
          <h2>Accounting</h2>
          <p>Streamline your sales processes, improve efficiency, and drive business growth with our advanced CRM solutions tailored to your business. RIO offers comprehensive Odoo CRM solutions to empower your business with efficient customer relationship management capabilities. Our expert team utilizes the powerful features of Odoo to deliver customized CRM solutions tailored to your specific business needs. Partner with RIO for Odoo CRM solutions and unlock the full potential of your customer relationships!</p>
        </div> 
        </div>
        </div>
        <div className='container'>
        <hr/>
      </div>
      <div className="container">
        <div className="sale_banner1">
        <img src={invoice} alt=""  className='sale_image' />
        <div className="sale_text_col1">
          <h2>Invoicing</h2>
          <p>RIO delivers efficient and customizable Odoo invoicing solutions tailored to streamline your billing processes, enhance accuracy, and improve cash flow management. Leveraging the powerful features of Odoo, we provide comprehensive solutions that empower you to create professional invoices, track payments, and manage customer accounts with ease.</p>
          <p>In other words, with our advanced solutions, you can accelerate invoicing. Reduce administrative overhead, and optimize cash flow management for your business.</p>
        </div> 
        </div>
        </div>
        <div className='container'>
        <hr/>
      </div>
      <div className="container">
        <div className="sale_banner1">
        <img src={expense} alt=""  className='sale_image' />
        <div className="sale_text_col1">
          <h2>Expenses</h2>
          <p>Utilize our Odoo expense management solutions to streamline your expense tracking processes. Enhance visibility into spending and improve reimbursement workflows for your employees. Leveraging the powerful features of Odoo, we provide customizable solutions that empower your employees to submit, track, and manage expenses efficiently. With our advanced solutions, you can optimize expense management. Reduce administrative overhead, and gain better control over your company's spending.</p>
          
        </div> 
        </div>
        </div>  
        <div className="web_bg">
            <h2 className='web_bg_title'>With a track record of successful
            implementations and satisfied clients</h2>
            <p className='web_bg_para'>RIO is trusted by businesses worldwide to deliver exceptional Odoo solutions.

Don't just take our word for it—hear directly from our satisfied clients.</p>
        </div>
        <div>
            <h3 className='web_contact_title'>Ready to transform your business with RIO's
            Odoo development services?</h3>
            <p className='web_contact_para'>Contact us today for a free consultation and discover how we can help you achieve your business goals.

</p>
            <ContactFrom/>
        </div>      
    </div>
  )
}

export default FinanceManagement
import React from 'react'
import '../../../css/contentMarketing.css'
import content from '../../../assets/images/contentmarketing.png'
import { BiCheck } from "react-icons/bi";
import ClientLogohead from '../../Home/ClientLogohead';
import CW from '../../../assets/images/contentmarketing1.png'
import CC from '../../../assets/images/content-creation.png'
import SEO from '../../../assets/images/SEO.png'
import contentDis from '../../../assets/images/content_fill.png'
import business from '../../../assets/images/businesspeople.png'
import plan from '../../../assets/images/plan-content.png'
import data from '../../../assets/images/data.png'
import digital from '../../../assets/images/digital-campaign.png'
import custom from '../../../assets/images/customer-service.png'
import market from '../../../assets/images/marketing-automation.png'
import optimze from '../../../assets/images/optimization.png'
import exchange from '../../../assets/images/exchange.png'
import logo from '../../../assets/images/riologo.png'
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import perform from '../../../assets/images/performance.png'
import aduit from '../../../assets/images/Content-Audits.png'
import marketauto from '../../../assets/images/marketing-auto.png'
import rating from '../../../assets/images/rating.png'
import webdevelop from '../../../assets/images/web-designing.png'
import ContactFrom from '../../Services/ContactFrom';


export const ContentMarketing = () => {
  return (
    <div>
      <div className='content_bg'>
    <div className="content_container">
      <div className="content_text_column">
      <h1 className='content_title'>Crafting Stories Building Brands, Driving Results</h1>
      <p className='brand_para'>Compelling content is the cornerstone of a successful marketing strategy in today’s digital landscape. At RIO, we help brands tell their story and connect with their audience in the most meaningful way. We specialize in creating and delivering top-notch content that drives engagement, builds brand loyalty, and boosts conversions.</p>
      <button className='content_button'>Let's connect <BiCheck className='content_icon1'/>

</button>
      </div>
    
<img src={content} alt="" className='content_banner_img'/>
    </div>
    
    </div>
    <ClientLogohead/>
    <div className='container'>
    <hr className='b-line' />
    </div>
    
      <h2 className='content_title1'>Why Invest in Quality Content?</h2>
      <p className='content_tx_description'>In the digital age, content marketing is not just a trend—it's a vital component of any successful business strategy. In other words, it is an indispensable tool for any business looking to thrive in the digital world. By investing in a robust content strategy, you can build brand awareness, engage your audience, drive traffic, generate leads, and ultimately, grow your business.</p>
    
            <section className='content_wapp'>
            <div className="black content-grid">
           <img src={business} alt="" /> 
          <h4 className='content-grid-title'>Builds Brand Awareness</h4>
          <p className='content-grid-description'>Content marketing helps you reach a wider audience by showcasing your expertise and sharing valuable information. Regularly publishing high-quality content positions your brand as an industry leader and keeps you top of mind with potential customers.</p>
          </div>
          <div className="black content-grid">
          <img src={plan} alt="" /> 
       
          <h4 className='content-grid-title'>Engages Your Audience</h4>
          <p  className='content-grid-description'>Engaging content captures the interest of your audience, encouraging them to interact with your brand. Whether through blog posts, social media, or videos, compelling content fosters a community around your brand, leading to stronger customer relationships.</p>
          </div>

            </section>
            <img src={logo} alt='' className='content_center_logo'/>
            <section className='content_wapp'>
            <div className="black content-grid">
           <img src={data} alt="" /> 
          <h4 className='content-grid-title'>Drives Organic Traffic</h4>
          <p className='content-grid-description'>SEO-optimized content improves your website's visibility in search engine results. By answering the questions your audience is asking, you attract organic traffic, reduce reliance on paid advertising, and increase your site’s authority.</p>
          </div>
          <div className="black content-grid">
          <img src={digital} alt="" /> 
          {/* <FontAwesomeIcon icon={faUncharted}  className='font_icon' /> */}
          
          <h4 className='content-grid-title'>Generates Leads and Conversions</h4>
          <p  className='content-grid-description'>Effective content marketing guides prospects through the buyer's journey. Informative and persuasive content can turn visitors into leads and leads into customers by addressing their pain points and offering solutions.</p>
          </div>

            </section>
            <section className='content_wapp'>
            <div className="black content-grid">
           <img src={custom} alt="" /> 
          <h4 className='content-grid-title'>Builds Trust and Credibility</h4>
          <p className='content-grid-description'>Consistently providing valuable, relevant content helps build trust with your audience. When people view your brand as a credible source of information, they are more likely to choose your products or services over competitors.</p>
          </div>
          <div className="black content-grid">
          <img src={market} alt="" />   
          <h4 className='content-grid-title'>Supports Other Marketing Efforts</h4>
          <p  className='content-grid-description'>Engaging content captures the interest of your audience, encouraging them to interact with your brand. Whether through blog posts, social media, or videos, compelling content fosters a community around your brand, leading to stronger customer relationships.</p>
          </div>

            </section>
            <img src={logo} alt='' className='content_center_logo'/>
            <section className='content_wapp'>
            <div className="black content-grid">
           <img src={optimze} alt="" /> 
          <h4 className='content-grid-title'>Cost-Effective Marketing Strategy</h4>
          <p className='content-grid-description'>Compared to traditional marketing methods, content marketing is cost-effective and provides long-term benefits. Quality content continues to attract traffic and generate leads long after it’s published, delivering ongoing RIO</p>
          </div>
          <div className="black content-grid">
          <img src={exchange} alt="" /> 
        
          
          <h4 className='content-grid-title'>Enhances Customer Retention</h4>
          <p  className='content-grid-description'>Keeping your existing customers engaged with fresh and relevant content helps maintain their loyalty. Regularly updated content ensures that customers continue to find value in your brand, reducing churn rates and promoting repeat business.</p>
          </div>

            </section>
            <h2 className='content_service_title'>Services We Offer</h2>
            <div className="content_container1">
              <img src={CW}  alt=''/>
              <div className="content_text_column">
              <h3 className='content_txt_title' >Content Strategy Development</h3>
              <p className='content_txt_para'>We'll work with you to develop a comprehensive content strategy that aligns with your business goals and audience needs. From content audits to editorial calendars, we cover all aspects to set you up for success.</p>
              
              <div className="content-progress"> 
            <div className="content-progress-bar">

            </div>
            <h4>Client<br/>Satisfied</h4>
                     <div className="content-progress-bar1"></div>
                       <h4>Grow
                       Your Business</h4>
                        </div>
                           
                      </div>
                  </div>
                  <div className="content_container2">
                        <div className="content_text_column">
                          <h3 className='content_txt_title1'>Content Creation</h3>
                          <p className='content_txt_para1'>Our team creates a wide range of content types, including</p>
                          <div className='content_icon_container'>

                          <IoIosCheckmarkCircleOutline className='content_icon'/>
       <div className="brand_cloumn">
      
      <h3>Blog Posts and Articles</h3>
      <p className='content_icon_para'>Informative and engaging pieces that establish

your brand as an authority in your industry.</p>
       </div>
                          </div>
                          
                          <div className='content_icon_container'>
       <IoIosCheckmarkCircleOutline className='content_icon'/>
        <div className="brand_column"> 
      
      <h3>Social Media Content</h3>
      <p className='content_icon_para'>Eye-catching posts that drive engagement and

grow your online presence.</p>
       </div> 
       </div>
       <div className='content_icon_container'>
       <IoIosCheckmarkCircleOutline className='content_icon'/>
       <div className="brand_cloumn">
      
      <h3>Email Campaigns</h3>
      <p className='content_icon_para'>Compelling newsletters and promotional

emails that nurture leads and boost sales.</p>
       </div>
       </div>
       <div className='content_icon_container'>
       <IoIosCheckmarkCircleOutline className='content_icon'/>
       <div className="brand_cloumn">
      
      <h3>Video Scripts and Production</h3>
      <p className='content_icon_para'>High-quality videos that tell your story

and captivate your audience.</p>
       </div>
       </div>
      
      </div>
                          <img src={CC} alt=''/>
                          </div>  
                    <div className='content_container2'>
                      <img src={SEO} alt="" />
                      <div className="content_text_column">
                        <h3 className='content_txt_title1'>SEO & Content Optimization</h3>
                        <p className='content_txt_para2'>Ensure your content gets found. We integrate SEO best practices into all our content, helping you rank higher in search results and attract organic traffic.</p>
                        <div className="content-progress"> 
          
                     <div className="content-progress-bar1"></div>
                       <h4>Grow
                       Your Business</h4>
                        </div>
                      </div>

                    </div>
                    <div className='content_container2'>
                    <div className="content_text_column1">
                      <h3 className='content_txt_title1'>Content Distribution</h3>
                      <p className='content_txt_para1'>We don't just create content; we make sure it reaches the right people. Our distribution services leverage multiple channels, including social media, email, and paid advertising, to amplify your message.</p>

                    </div>
                    <img src={contentDis} alt=''/>
                      </div> 
                      
      <div className='content_container1'>
      <img src={perform} alt=''/>
      <div className="content_text_column1">
                      <h3 className='content_txt_title1'>Performance Analytics</h3>
                      <p className='content_txt_para2'>Track the success of your content with our detailed performance reports. We provide insights into what's working and what needs improvement, so you can continually refine your strategy.</p>
                      <div className="content-progress"> 
            <div className="content-progress-bar">

            </div>
            <h4>Client<br/>Satisfied</h4>
            </div>

                    </div>
      </div>
      <div className='content_container2'>
                    <div className="content_text_column1">
                      <h3 className='content_txt_title1'>Content Audits and Refreshes</h3>
                      <p className='content_txt_para1'>Stay relevant and up-to-date with our content audit and refresh services. We review your existing content, identify gaps, and update outdated information to ensure your audience always receives the most current and valuable insights.</p>

                    </div>
                    <img src={aduit} alt=''/>
                      </div> 
                      <h2 className='content_service_title'>Why Choose Us?</h2>
                  <div className='content_grid3'>
                  <div className="content_grid3_bg">
                    <img src={marketauto} alt="" className='content-grid3_img'/>
                    <h4>
                    Tailored Content Strategies
                    </h4>
                    <p>Every brand is unique, and so are our content strategies. We take the time to understand your business goals, target audience, and industry dynamics to craft a personalized content plan that resonates with your customers and achieves your objectives.</p>
                  </div>
                  <div className="content_grid3_bg">
                    <img src={rating} alt=""  className='content-grid3_img'/>
                    <h4>
                    Expert Team of Creatives
                    </h4>
                    <p>Our team comprises seasoned writers, designers, and strategists who bring a wealth of experience and creativity to the table. Whether it's blog posts, social media content, email newsletters, or video scripts, we produce high-quality content that captivates and converts.</p>
                  </div>
                  <div className="content_grid3_bg">
                    <img src={webdevelop} alt="" className='content-grid3_img'/>
                    <h4>
                    Data-Driven Approach
                    </h4>
                    <p>At RIO, we believe in the power of data. Our strategies are backed by thorough research and analytics, ensuring that every piece of content is optimized for performance. We continuously monitor and adjust our tactics to maximize your RIO.</p>
                  </div>
                  </div>
                  <h2 className='content_service_title'>Let’s Discuss Your Project!</h2>
                  <p className='content_tx_description1'>Ready to take your content marketing to the next level? Partner with RIO and watch your brand thrive. Contact us today for a free consultation and discover how we can help you achieve your marketing goals.</p>
                  <ContactFrom/>
    </div>
  )
}

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

import Header from './components/Navbar';
import Home from './components/pages/Home';
import Footerbottom from './components/Footerbottom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Blogs, MainServices } from './components/pages';
import Contact from './components/pages/Contact';
import Staffing from './components/Services/Staffing';
import Digitalmarketing from './components/Services/Digitalmarketing';
import Ipr from './components/Services/Ipr';
import Odoo from './components/Services/Odoo';
import Website from './components/sub_pages/Odoo/Website'
import SaleManagement from './components/sub_pages/Odoo/SaleManagement'
import Branding from './components/sub_pages/DigitalMarketing/Branding'
import AppDevelopment from './components/Services/AppDevelopment';
import { ContentMarketing } from './components/sub_pages/DigitalMarketing/ContentMarketing';
import PayPerClick from './components/sub_pages/DigitalMarketing/PayPerClick';
import SocialMediaMarketing from './components/sub_pages/DigitalMarketing/SocialMediaMarketing';
import Seo from './components/sub_pages/DigitalMarketing/Seo';
import WebDevelopment from './components/Services/WebDevelopment';
import FinanceManagement from './components/sub_pages/Odoo/FinanceManagement';
import InventoryManufacturing from './components/sub_pages/Odoo/InventoryManufacturing';
import HumanResource from './components/sub_pages/Odoo/HumanResource';
import Marketing from './components/sub_pages/Odoo/Marketing';
import OdooServices from './components/sub_pages/Odoo/OdooServices';
import Productivity from './components/sub_pages/Odoo/Productivity';
import Customization from './components/sub_pages/Odoo/Customization';
import Iosapp from './components/sub_pages/AppDevelopment/Iosapp';

function App() {
  return (
  <Router>
<div className="App">
 <Header />
  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/service' element={<MainServices/>} />
    <Route path='/blog' element={<Blogs/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/our-service/staffing' element={<Staffing/>}/>
    <Route path='/our-service/digital-marketing/' element={<Digitalmarketing/>}/>
    <Route path='/our-service/app-development' element={<AppDevelopment/>}/>
    <Route path='/our-service/web-development' element={<WebDevelopment/>}/>
    <Route path='/our-service/ipr/' element={<Ipr/>}/>
    <Route path='/our-service/odoo/' element={<Odoo/>}/>
     <Route path ='/our-service/digital-marketing/branding' element={<Branding/>}/>
     <Route path ='/our-service/digital-marketing/content-marketing' element={<ContentMarketing/>}/>
     <Route path ='/our-service/digital-marketing/pay-per-click' element={<PayPerClick/>}/>
     <Route path ='/our-service/digital-marketing/social-media-marketing' element={<SocialMediaMarketing/>}/>
     <Route path ='/our-service/digital-marketing/seo' element={<Seo/>}/>
     <Route path ='/our-service/odoo/website' element={<Website/>}/>
     <Route path ='/our-service/odoo/sale-management' element={<SaleManagement/>}/>
     <Route path ='/our-service/odoo/finance-management' element={<FinanceManagement/>}/>
     <Route path ='/our-service/odoo/inventory-manfacturing' element={<InventoryManufacturing/>}/>
     <Route path ='/our-service/odoo/human-resource' element={<HumanResource/>}/>
     <Route path ='/our-service/odoo/marketing' element={<Marketing/>}/>
     <Route path ='/our-service/odoo/services' element={<OdooServices/>}/>
     <Route path ='/our-service/odoo/productivity' element={<Productivity/>}/>
     <Route path ='/our-service/odoo/customization' element={<Customization/>}/>
    <Route path ='/our-service/app-development/custom-ios-android-app-development' element={<Iosapp/>}/>
    {/* <Route path ='/slider' element={<Slider/>}/> */}
     
     
  </Routes> 
    <Footerbottom/>    
    </div>
    </Router>

   
  );
}

export default App;

import React from 'react'
import '../../css/Clientlogo.css'
import Kumaraguru from '../../assets/images/kumaraguru.jpg';
import Amazon from '../../assets/images/Amazon.jpg';
import Aplus from '../../assets/images/Aplus.jpg';
import Dare2escape from '../../assets/images/Dare2escape.jpg';
import khadheejah from '../../assets/images/khadheejah.jpg';
import Kmdecors from '../../assets/images/Km-Decors.jpg';
import Shobha from '../../assets/images/Shobha.jpg';


function Clientlogo() {
  return (
    <div className='client-logo'>
        {/* <h1 className='client'>Our Clients</h1> */}
        <div className='logo'>
        <div className='logo-slid'>
            <img src={Kumaraguru} alt="" />
            <img src={Amazon} alt=""/>
            <img src={Aplus} alt=""/>
            <img src={Dare2escape} alt="" />
            <img src={khadheejah} alt="" />
            <img src={Kmdecors} alt="" />
            <img src={Shobha} alt="" />
        </div>
        </div>
       
        
        </div>
  )
}

export default Clientlogo
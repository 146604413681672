import React from 'react'
import '../../css/Team.css';
import Tony from '../../assets/images/Tony.jpg';
import Kavith from '../../assets/images/Kavitha.jpg';
import Shilpa from '../../assets/images/Shilpa.jpg';
import {Link} from 'react-router-dom'

function Team() {
  return (
    <div className='col-12'>
           <h1 className='team_title'>THE TRIO BEHIND RIO’S GROWTH</h1>
               <p className='team_des'>Meet the powerhouse trio behind Rio Business Solutions - the driving force that made it all possible. Their visionary leadership, financial expertise, and operational excellence have made everything possible, driving success for both our company and clients. Their teamwork is the foundation of Rio’s growth and impact.</p>
   
        <div className='team_contanier'>
          <div className="team_card">
          <div className='card__article '>
                    <img src={Kavith} alt='' className='team_img'/>
                   <div className='card_data'>
                   <Link to ="https://www.linkedin.com/in/kavitha-sylvia-a26168202/"  target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'black'}}><h4 className='card_title'> Kavitha Sylvia</h4>
                      <span>CFO</span></Link> 
                   </div>
              </div>
              <div className='card__article '>
                  <img src={Tony} alt='' className='team_img'/>
                      <div className='card_data'>
                      <Link to ="https://www.linkedin.com/in/tony-rozario/"  target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}><h4 className='card_title'> Tony Rozario</h4>
                        <span>CEO</span></Link>
                      </div>
                </div>
                <div className='card__article '>
               <img src={Shilpa} alt='' className='team_img'/>
                <div className='card_data'>
                <Link to ="https://www.linkedin.com/in/j-maria-shilpa-2ab9a71b9/"  target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'black'}}><h4 className='card_title'> Maria Shilpa</h4>
                    <span>COO</span></Link>
                </div>
                </div> 
          </div>
          {/* <div className="container">
            <div className="row">
              <div className='col-lg-4 col-md-6  '>

               


               <div className='card__article '>
                    <img src={Kavith} alt='' className='team_img'/>
                   <div className='card_data_overlay '>
                   <Link to ="https://www.linkedin.com/in/kavitha-sylvia-a26168202/"  target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}><h4 className='card_title'> Kavitha Sylvia</h4>
                      <span>CFO</span></Link> 
                   </div>
              </div>
              </div>
              <div className='col-lg-4 col-md-6 text-align-center'>

                <div className='card__article '>
                  <img src={Tony} alt='' className='team_img'/>
                      <div className='card_data_overlay '>
                      <Link to ="https://www.linkedin.com/in/tony-rozario/"  target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}><h4 className='card_title'> Tony Rozario</h4>
                        <span>CEO</span></Link>
                      </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>

              <div className='card__article '>
               <img src={Shilpa} alt='' className='team_img'/>
                <div className='card_data_overlay '>
                <Link to ="https://www.linkedin.com/in/j-maria-shilpa-2ab9a71b9/"  target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}><h4 className='card_title'> Maria Shilpa</h4>
                    <span>COO</span></Link>
                </div>
                </div> 
              </div>
            </div> 
             </div> */}
          
         
        
            
        </div>
    </div>
  )
}

export default Team